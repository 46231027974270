// import LogIn from "../Components/authentication/logIn";
import News from "../Components/news/news";

export default function SignIn() {
  return (
    <>
      <News />
    </>
  );
}
