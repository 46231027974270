import logo from "./logo.svg";
import "./App.css";
import React from "react";
// import Sponsors from "./pages/aboutUs";
import { Router, Route, Routes } from "react-router-dom";
import NavBar from "./Components/navbar/navbar";
import Footer from "./Components/footer/footer";
import Home from "./pages/home";
import Sponsors from "./Components/sponsors/sponsors";
import SignIn from "./pages/signIn";
import Register from "./Components/register/register";
import Ca from "./Components/campusAmbassador/Ca";
import EventsPage from "./pages/eventsPage";
import TeamPage from "./pages/teamPage";
import { useEffect } from "react";
import { motion } from "framer-motion";
import { Analytics } from "@vercel/analytics/react";
import EventsAdmin_ from "./pages/admin";
import { SpeedInsights } from "@vercel/speed-insights/react"
// import Parallax from "./Components/intro/Components/Parallax";

function App() {
  const [activeUrl, setactiveUrl] = React.useState(window.location.pathname);
  const [startOnce, setstartOnce] = React.useState(true);
  const [loading, setloading] = React.useState(true);

  useEffect(() => {
    setTimeout(() => {
      setstartOnce(false);
    }, 5000);
  }, []);

  return (
    <>
      <Analytics mode={"production"} />
      {startOnce === true && (
        <motion.div
          style={{
            backgroundColor: "black",
            height: "100vh",
            position: "fixed",
            width: "100%",
            zIndex: 200,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <video
            autoPlay
            muted
            playsInline
            onLoadedData={() => setloading(false)}
            style={{
              maxWidth: "80%",
              maxHeight: "80%",
              borderRadius: "20px",
            }}
          >
            <source src="/0407.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </motion.div>
      )}
      <NavBar activeUrl={activeUrl} setactiveUrl={setactiveUrl} />
      {/* <Parallax /> */}
      <Routes>
        <Route
          path="/"
          element={
            <Home
              activeUrl={activeUrl}
              setactiveUrl={setactiveUrl}
              startOnce={startOnce}
              loading={loading}
              setloading={setloading}
            />
          }
        />
        <Route path="/admin" element={<EventsAdmin_ />} />
        <Route path="/sponsors" element={<Sponsors />} />
        <Route
          path="/news"
          element={
            <SignIn
              activeUrl={activeUrl}
              setactiveUrl={setactiveUrl}
              startOnce={startOnce}
              loading={loading}
              setloading={setloading}
            />
          }
        />
        <Route
          path="/events"
          element={
            <EventsPage
              activeUrl={activeUrl}
              setactiveUrl={setactiveUrl}
              startOnce={startOnce}
              loading={loading}
              setloading={setloading}
            />
          }
        />
        <Route path="/register" element={<Register />} />
        <Route path="/CampusAmbassador" element={<Ca />} />
        <Route path="/teams" element={<TeamPage />} />
      </Routes>
      <div
        style={{
          zIndex: 900,
        }}
      >
        <Footer activeUrl={activeUrl} setactiveUrl={setactiveUrl} />
      </div>
    </>
  );
}
<SpeedInsights/>
export default App;
