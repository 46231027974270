import React from "react";
// import "./App.css";
import Container from "../Components/teamsPage/TeamPage";
// import NavBar from "../Components/teamsPage/navbar";

function TeamPage() {
  return (
    <div className="TeamPage">
      {/* <NavBar /> */}
      <Container/>
    </div>
  );
}

export default TeamPage;
